






import { Component, Vue } from 'vue-property-decorator'
import { batches } from '@/firebase'
import { Batch } from '@ht-lib/preproduction-models'
import Table from './Table.vue'
import firebase from 'firebase/app'


@Component({
  components: { 'BatchTable': Table }
})
export default class extends Vue {
  myBatches: Batch[] = []
  batches: Batch[] = []
  unsubscribe: (() => void) | undefined = undefined

  created () {
    this.unsubscribe = batches
      .where('state', '<', 15)
      .onSnapshot(snapshot => {
        snapshot
          .docChanges()
          .map(change => ({
            change,
            batch: change.doc.data() as Batch
          }))
          .sort((a, b) => a.batch.timestamp - b.batch.timestamp)
          .forEach(this.callback)
      })
  }

  callback ({ change, batch }: { change: firebase.firestore.DocumentChange; batch: Batch }) {
    const isThisUser = this.isThisUser(batch)
    switch (change.type) {
      case 'added':
        if (isThisUser) {
          return this.addBatch(batch, this.myBatches)
        } else {
          return this.addBatch(batch, this.batches)
        }
      case 'removed':
        this.removeBatch(batch, this.myBatches)
        this.removeBatch(batch, this.batches)
        break
      case 'modified':
        if (isThisUser) {
          return this.batchModified(batch, this.batches, this.myBatches)
        } else {
          return this.batchModified(batch, this.myBatches, this.batches)
        }
    }
  }

  isThisUser = (batch: Batch) => batch.user !== undefined && batch.user.uid === this.$store.state.profile.uid
  inList = (batch: Batch, list: Batch[]) => list.some(x => x.id === batch.id)
  indexOf = (batch: Batch, list: Batch[]) => list.findIndex(x => x.id === batch.id)
  sortList = (list: Batch[]) => list.sort((a, b) => a.timestamp - b.timestamp)

  addBatch (batch: Batch, list: Batch[]) {
    const notFound = !this.inList(batch, list)
    if (notFound) {
      list.push(batch)
    }

    list = this.sortList(list)
  }

  removeBatch (batch: Batch, list: Batch[]) {
    const index = this.indexOf(batch, list)
    if (index !== -1) {
      list.splice(index, 1)
    }
  }

  batchModified (batch: Batch, listA: Batch[], listB: Batch[]) {
    if (this.inList(batch, listA)) {
      this.removeBatch(batch, listA)
    }

    if (this.inList(batch, listB)) {
      this.modifyBatch(batch, listB)
    } else {
      this.addBatch(batch, listB)
    }
  }

  modifyBatch (batch: Batch, list: Batch[]) {
    const index = this.indexOf(batch, list)
    if (index !== -1) {
      this.$set(list, index, batch)
    }
  }

  beforeDestroy () {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }
}

import { render, staticRenderFns } from "./Stats.vue?vue&type=template&id=1d35b53c&scoped=true&lang=pug&"
import script from "./Stats.vue?vue&type=script&lang=ts&"
export * from "./Stats.vue?vue&type=script&lang=ts&"
import style0 from "./Stats.vue?vue&type=style&index=0&id=1d35b53c&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d35b53c",
  null
  
)

export default component.exports
import QMarkupTable from 'quasar/src/components/markup-table/QMarkupTable.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QMarkupTable,QBadge});

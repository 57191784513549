























import { Component, Emit, Prop, Watch, Vue } from 'vue-property-decorator'
import BatchList from '@/components/BatchList/BatchList.vue'
import { BannerModel } from '../components/Banner.vue'
import { Route } from 'vue-router'
import Stats from '@/components/Stats.vue'
import { mapState } from 'vuex'

Component.registerHooks(['beforeRouteLeave'])

@Component({
  components: { BatchList, Stats },
  computed: {
    ...mapState(['newVersionAvailable']),
    ...mapState('profile/roles', ['viewAdminReports']),
  }
})
export default class extends Vue {
  @Prop() searchParam!: string
  @Prop() jobLoading!: boolean
  newVersionAvailable!: boolean
  viewAdminReports!: boolean
  jobInput = ''

  @Emit('banner')
  beforeRouteLeave (to: Route, from: Route, next: () => void) {
    next()
    return {}
  }

  get styleClasses () {
    return ['bg-grey-4']
  }

  created () {
    this.$store.dispatch('init')
  }

  jobSearch (accountJob: string) {
    const isBatch = accountJob.toLowerCase().indexOf('b-') !== -1
    if (isBatch) {
      const [, batchId] = accountJob.split('-')
      this.$router.push(`/batch/${ batchId }`)
    } else {
      const [accountId, jobId] = accountJob.includes('/')
        ? accountJob.split('/')
        : accountJob.split('-')
      this.$router.push(`/account/${ accountId }/job/${ jobId }`)
    }
  }

  @Watch('newVersionAvailable')
  onNewVersionAvailable () {
    const banner: BannerModel = {
      color: 'accent',
      message: 'There is an updated version available',
      dismissable: false,
      action: {
        label: 'Reload',
        fn: location.reload.bind(window.location)
      }
    }

    this.$emit('banner', banner)
  }
}






































import { Component, Vue } from 'vue-property-decorator'
import { StatsDoc } from '@ht-lib/preproduction-models'
import { mapState } from 'vuex'
import numbro from 'numbro'

@Component({
  computed: { ...mapState('profile', ['stats']) }
})
export default class extends Vue {
  stats!: StatsDoc
  hover = false

  getValue (value: number) {
    const number = numbro(value)
    return this.hover
      ? number.format({ thousandSeparated: true })
      : number.format({ average: true, mantissa: 1, trimMantissa: true })
  }
}

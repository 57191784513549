


























































import { Component, Prop, Vue } from 'vue-property-decorator'
import date from 'quasar/src/utils/date.js';
import { getBatchStateText, canQA, unassignUser } from '@/batchList'
import { Batch, BatchState } from '@ht-lib/preproduction-models'
import { mapState } from 'vuex'

@Component({
  computed: {
    ...mapState(['newVersionAvailable']),
    ...mapState('profile/roles', ['unassignOthersFromBatches'])
  }
})
export default class extends Vue {
  @Prop() items!: Batch[]
  @Prop() title!: string
  newVersionAvailable!: boolean
  unassignOthersFromBatches!: boolean
  batchStateText = getBatchStateText

  get buttonState () {
    return (item: Batch) => !canQA(item)
  }

  stateColourClass (state: BatchState): string {
    switch (state) {
      case BatchState.INPROGRESS:
        return 'positive'
      case BatchState.ONHOLD:
      case BatchState.WAITING_EDITING:
      case BatchState.WAITINGIMAGES:
        return 'warning'
      case BatchState.ERROR:
        return 'negative'
      case BatchState.READY:
        return 'primary'
      default:
        return ''
    }
  }

  itemClass (item: Batch): string {
    switch (item.state) {
      case BatchState.INPROGRESS:
        return 'in-progress'
      case BatchState.ONHOLD:
        return 'on-hold'
      case BatchState.WAITINGIMAGES:
        return 'waiting-images'
      case BatchState.ERROR:
        return 'error'
      case BatchState.READY:
        return 'ready'
      default:
        return ''
    }
  }

  unassign (batch: Batch) {
    if (batch.user === undefined) return
    const user = batch.user.uid === this.$store.state.profile.uid
      ? 'yourself'
      : batch.user.name

    this.$q.dialog({
      title: 'Unassign User',
      message: `This will unassign ${ user } from batch ${ batch.id }, are you sure?`,
      cancel: true
    }).onOk(() => unassignUser(batch.id))
  }

  batchDate (item: Batch) {
    const offset = new Date().getTimezoneOffset() * 60000
    return date.formatDate(item.timestamp + offset, 'DD-MM-YY HH:mm')
  }
}
